<template>
  <div>
    <div class="nav">
      <h1>首页</h1>
    </div>
    <div class="datas">
        <div class="result">
            <span></span>
            <p>数据概括</p>
        </div>
        <div class="item">
            <div class="list">
                <p>{{ con.days }}</p>
                <span>今日访问</span>
            </div>

            <div class="list">
                <p>{{ con.months }}</p>
                <span>本月访问</span>
            </div>

            <div class="list">
                <p>{{ con.sum }}</p>
                <span>总访问</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { homes } from '@/api/data'
export default {
  data() {
    return {
        con: {}
    };
  },
  created() {
    homes().then(res => {
        this.con = res.data
    })
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>
    .datas {
        width: 100%;
        margin-left: 5px;
        .item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            .list {
                width: 33%;
                margin-bottom: 5px;
                p {
                    padding-top: 20px;
                    font-size: 30px;
                    margin-bottom: 5px;
                    font-weight: 800;
                    color: #ed7d30;
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }
</style>